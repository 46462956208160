<template>
  <v-theme-provider
  	class="grey lighten-4">
    <base-section
      id="teams"
      space="40"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <base-section-heading title="Insan Takaful Umum" />
            <base-body space="0">
               <v-container fluid>
               	<v-progress-linear :indeterminate="true" stream v-if="loading"></v-progress-linear>
                <v-row dense>
                  <v-col
                    v-for="card in posts"
                    :key="card.users_id"
                    md="2"
                    sm="4"
                    xs="4"
                  >
                    <div 
                      data-aos="fade-in"
                      data-aos-delay="50"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                      data-aos-mirror="true"
                      data-aos-once="true"
                      data-aos-anchor-placement="top-center">
                    <v-card
                    outlined>
                      <v-img
                        :src="card.users_avatar_url"
                        class="white--text align-end"
                        :alt="card.users_name"
                      >
                        <!-- <v-card-title class="caption font-weight-black" v-text="card.users_name"></v-card-title> -->
                      </v-img>
                      <v-card-actions class="caption">
                        {{ card.users_name }}
                      </v-card-actions>
                    </v-card>
                	</div>
                  </v-col>
                </v-row>
              </v-container>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionTeams',
    data: () => ({
    		loading: false,
            posts: [],
    }),
    created () {
    	this.List()
    },
    methods: {
    	List () {
    		this.loading  = true
        let formdata = {
        		users_id: '',
        		users_employeef: 'Y',
        		users_bodf: 'N',
        		users_actived: 'Y',
        		order_by: 'users_id',
        		order_type: 'ASC',
        }
	    	let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWebTeamsList'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            this.posts = response.data
            this.loading  = false
        })
        .catch(e => {
            alert(e)
            this.loading  = false
        })
	    },
    }
  }
</script>
